



























































import Vue from 'vue';
import * as Api from '@/api/bannerManage';
const columns = [
  {
    title: 'banner图片',
    dataIndex: 'bannerUrl',
    key: 'bannerUrl',
    align: 'center',
    scopedSlots: { customRender: 'bannerUrl' },
  },
  {
    title: '跳转路径',
    dataIndex: 'extend1',
    key: 'extend1',
    align: 'center',
    width: '300px',
  },
  {
    title: '排序',
    dataIndex: 'sort',
    key: 'sort',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];
const dataSource = [];
export default Vue.extend({
  name: 'dictList',
  data() {
    return {
      tableLoading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      formState: {
        dictName: '',
        dictCode: '',
      },
      dataSource,
      columns,
      formVisible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      loading: false,
      form: {
        bannerUrl: '',
        sort: '',
        extend1: '',
      },
      rules: {
        bannerUrl: [
          {
            required: true,
            message: '请选择banner图片',
            trigger: 'change',
          },
        ],
        sort: [
          {
            required: true,
            message: '请输入排序',
            trigger: 'change',
          },
        ],
      },
      deleteVisible: false,
      deleteId: 0,
      optionType: '',
      previewVisible: false,
      previewImage: '',
    };
  },
  created() {
    this.handleSubmit();
  },
  methods: {
    updateApplicationLogoUrl(url: string) {
      this.$set(this.form, 'bannerUrl', url);
    },
    // 查询按钮
    handleSubmit() {
      this.pagination.current = 1;
      this.getDataList();
    },
    getDataList() {
      const param = {
        page: this.pagination.current,
        limit: this.pagination.pageSize,
      };
      this.tableLoading = true;
      Api.listPage(param)
        .then((res) => {
          const data = res;
          if (data.status === 200) {
            this.dataSource = res.result;
            this.pagination.total = res.result.total;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 主列表翻页控制
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    // 新增字典目录
    newForm(record, optionType) {
      this.optionType = optionType;
      if (optionType === 'add') {
        if (this.dataSource.length >= 10) {
          this.$message.warning(
            '门户站最多显示10条数据，请先删除数据或重新编辑数据'
          );
          return false;
        }
      }
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
        this.$nextTick(() => {
          if (optionType === 'edit') {
            this.form = JSON.parse(JSON.stringify(record));
          }else{
            this.form={
              bannerUrl: '',
              sort: '',
              extend1: ''
            }
          }
        });
      });
    },
    // 字典目录表单提交
    onSubmit() {
      (this.$refs.ruleForm as HTMLFormElement).validate((valid) => {
        if (valid) {
          if (this.optionType === 'add') {
            Api.dataAdd(this.form)
              .then((res) => {
                if (res) {
                  this.$message.success('新增成功！');
                  this.loading = true;
                  this.handleSubmit();
                  this.formVisible = false;
                }
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            Api.dataEidt(this.form)
              .then((res) => {
                if (res) {
                  this.$message.success('修改成功！');
                  this.loading = true;
                  this.handleSubmit();
                  this.formVisible = false;
                }
              })
              .finally(() => {
                this.loading = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    cancel() {
      this.formVisible = false;
      this.handleSubmit();
    },
    // 删除字典目录
    deleteDict(record) {
      this.deleteVisible = true;
      this.deleteId = record.sequenceNbr;
    },
    deleteConfirm() {
      Api.dataDelete([this.deleteId])
        .then((res) => {
          if (res) {
            this.loading = true;
            this.deleteVisible = false;
            this.handleSubmit();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCancel() {
      this.previewVisible = false;
    },
    imageView(url) {
      this.previewImage = url;
      this.previewVisible = true;
    },
  },
});
